/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from './locale';

// to be used with the `format` function of dayjs
// https://day.js.org/docs/en/display/format
export const dayjsFormats = {
  weekDayMonthAndDay: 'ddd, MMM D',
  // Mon,
  timeOnly: 'h:mm A',
};

export const dayjsFormatsWithFullName = {
  weekDayMonthAndDay: 'dddd, MMMM D',
  // Mon,
  timeOnly: 'h:mm A',
};

export const dateFormatterForSTH = {
  weekDayMonthAndDay: ' MMM. D',
  // Mon,
  timeOnly: 'h:mm A',
};

export const dateFormatterForSTHWithoutDot = {
  weekDayMonthAndDay: ' MMM D',
  // Mon,
  timeOnly: 'h:mm A',
};

export const dateFormatterForSTHCheckout = {
  weekDayMonthAndDay: 'ddd, MMM D',
  timeOnly: 'h:mm A',
};

export const days = {
  us: {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
  },
  mx: {
    lunes: 'lunes',
    martes: 'martes',
    miércoles: 'miércoles',
    jueves: 'jueves',
    viernes: 'viernes',
    sábado: 'sábado',
    domingo: 'domingo',
  },
  br: {
    Monday: 'Segunda-feira',
    Tuesday: 'Terça-feira',
    Wednesday: 'Quarta-feira',
    Thursday: 'Quinta-feira',
    Friday: 'Sexta-feira',
    Saturday: 'Sábado',
    Sunday: 'Domingo',
  },
};

export const daysArray = {
  'en-US': [
    days.us.Sunday,
    days.us.Monday,
    days.us.Tuesday,
    days.us.Wednesday,
    days.us.Thursday,
    days.us.Friday,
    days.us.Saturday,
  ],
  'es-MX': [
    days.mx.domingo,
    days.mx.lunes,
    days.mx.martes,
    days.mx.miércoles,
    days.mx.jueves,
    days.mx.viernes,
    days.mx.sábado,
  ],
  'pt-BR': [
    days.br.Sunday,
    days.br.Monday,
    days.br.Tuesday,
    days.br.Wednesday,
    days.br.Thursday,
    days.br.Friday,
    days.br.Saturday,
  ],
};

export const localeDateFormats = {
  [countryCodes.us]: 'MM/DD/YYYY',
  [countryCodes.mx]: 'DD/MM/YYYY',
  [countryCodes.ptBr]: 'DD/MM/YYYY',
};
